.App {
  text-align: center;
}

.left-menu {
  position: absolute;
  top: 120px;
  bottom: 0;
  left: 0;
  right: -10px;
  overflow-y: scroll;
  overflow-x: visible;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
 
/* Thin scrollbar in the app (currently supports webkit based browsers only) */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  background-color: #ACACAC;
}

.lang-switch-container {
  position: absolute;
  top: 1%;
  right: 1%;
  z-index: 11000;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*--------- START Toggle --------*/

.switch {
  position: relative;
  display: inline-block;
  width: 69px;
  height: 25px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #3485f7;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  width: 27.8px;
  height: 27.8px;
  border: solid 2px #3485f7;
  background-color: #ffffff;
  left: -2px;
  bottom: -1px;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked+.slider {
  background-color: #3485f7;
}

input:focus+.slider {
  box-shadow: 0 0 1px #3485f7;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(45px);
}

/*------ ADDED CSS ---------*/

.slider:after {
  content: 'ZH';
  color: #ffffff;
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 70%;
  font-size: 14px;
  font-family: Verdana, sans-serif;
}

input:checked+.slider:after {
  content: 'EN';
  left: 30%;
}

/*--------- END Toggle --------*/
option:checked {
  color:'black'
}