.loginBtn {
    margin-top: 20px;
    color: rgb(255, 255, 255);
    padding: 20px 32px;
    border-radius: 50px;
    background-color: #6639e4;
    -webkit-transition: background-color 350ms ease, -webkit-transform 350ms ease;
    transition: background-color 350ms ease, -webkit-transform 350ms ease;
    transition: transform 350ms ease, background-color 350ms ease;
    transition: transform 350ms ease, background-color 350ms ease, -webkit-transform 350ms ease;
    font-size: 16px;
    line-height: 1em;
    font-weight: 700;
    text-align: center;
    letter-spacing: .1em;
    text-transform: uppercase;
    border: none;
    width: 100%;
}

.welcomeHeading {
    margin-top: 0;
    margin-bottom: 22px;
    font-size: 60px;
    line-height: 1.212em;
    font-weight: 700;
    letter-spacing: -.01em;
    color:#ffff
}

.inputLabel {
    display: block;
    margin-bottom: 12px;
    line-height: 1.444em;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: .05em;
    text-transform: uppercase;
    color: #fff;   
}

.smallHeading {
    margin-bottom: 13px;
    font-size: 18px;
    line-height: 1.409em;
    letter-spacing: .01em;
    margin-top: 0;
    margin-bottom: 16px;
    line-height: 1.31em;
    font-weight: 700;
    letter-spacing: -.01em;
    color: #fff;
}

.inputStyle {
    margin-bottom: 35px !important;
    margin-top: 0 !important;
    padding-right: 24px !important;
    padding-left: 24px !important;
    border-radius: 100px !important;
    border: none;
    background-color: #282454 !important;
    transition: border-color 350ms ease, color 350ms ease;
    color: #fff !important;
    font-size: 18px !important;
    line-height: 1.333em !important;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    transition: color #000 !important;}

@media screen and (max-width: 600px) {
    .welcomeHeading {
        font-size: 30px;
    }
    .smallHeading {
        font-size: 16px;
    }
}

@media screen and (min-width: 601px) and (max-width: 767px) {
    .welcomeHeading {
        font-size: 40px;
    }
    .smallHeading {
        font-size: 18px;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .welcomeHeading {
        font-size: 50px;
    }
    .smallHeading {
        font-size: 20px;
    }
}